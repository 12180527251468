<template>
  <div>
    <v-card class="elevation-1" :loading="loading">
      <v-card-title
          v-if="$route.params.id !== undefined"
        >{{ transaction !== null ? transaction.reference_number : ''}}</v-card-title>
      <v-card-text>
        <v-form>
          <v-row class="mt-0 pt-0 pb-0">
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <v-autocomplete
                v-model="customer_type"
                outlined
                dense
                clearable
                label="Customer Type"
                item-text="title"
                item-value="id"
                :items="['WALKIN','MEMBER','STOCKIST']"
                :class="formErrorFields.customer_typeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.customer_typeErrorMessage"
                @change="customerTypeWasChange"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="customer_type !=='WALKIN'" class="mt-0 pt-0 pb-0">
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <!-- Customer Code -->
              <v-text-field
                v-model="customer_code"
                label="Customer Code"
                outlined
                dense
                placeholder="Enter Customer Code"
                :class="formErrorFields.customer_codeErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.customer_codeErrorMessage"
                @keydown="formError().remove('customer_code', formErrorFields)"
                @keypress.enter="getCustomer"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-0 pt-0 pb-0">
            <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
              <!-- Customer Name -->
              <v-text-field
                v-model="customer_name"
                label="Customer Name"
                outlined
                dense
                placeholder="Juan Dela Cruz"
                :readonly="customer_type !=='WALKIN'" 
                :class="formErrorFields.customer_nameErrorMessage.length > 0 ? 'mb-3' : ''"
                :error-messages="formErrorFields.customer_nameErrorMessage"
                @keydown="formError().remove('customer_name', formErrorFields)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="elevation-1 mt-2" :loading="loading">
      <v-card-title class="">Payment Breakdown</v-card-title>
      <v-card-text>
        <v-row class="pb-0">
          <v-col cols="12" md="6" lg="6" class="mb-0 pb-0">
            <a
              href="javascript:void(0)"
              class="subtitle-2 text-decoration-none"
              @click="paymentBreakdownWasClick('cash')"
            >CASH:</a>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mb-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(payments.cash) }}
            </h5>
          </v-col>
        </v-row>
        
        <v-row class="pb-0 pt-0 mt-1">
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <a
              href="javascript:void(0)"
              class="subtitle-2 text-decoration-none"
              @click="paymentBreakdownWasClick('gcash')"
            >GCASH:</a>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(payments.gcash) }}
            </h5>
          </v-col>
        </v-row>

        <v-row class="pb-0 pt-0 mt-1">
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <a
              href="javascript:void(0)"
              class="subtitle-2 text-decoration-none"
              @click="paymentBreakdownWasClick('bank transfer')"
            >BANK TRANSFER:</a>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(payments.bankTransfer) }}
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="elevation-1 mt-2" :loading="loading">
      <v-card-text>
        <v-row class="pb-0">
          <v-col cols="12" md="6" lg="6" class="mb-0 pb-0">
            <h5 class="subtitle-2">TOTAL ITEMS:</h5>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mb-0 pb-0">
            <h5 class="subtitle-2 text-right">{{ totalCartItems }}</h5>
          </v-col>
        </v-row>
        
        <v-row class="pb-0 pt-0 mt-1">
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2">AMOUNT DUE:</h5>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(totalDue) }}
            </h5>
          </v-col>
        </v-row>

        <v-row class="pb-0 pt-0 mt-1">
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2">TOTAL PAYMENT:</h5>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(totalPayment) }}
            </h5>
          </v-col>
        </v-row>

        <v-row class="pb-0 pt-0 mt-1 mb-1">
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2">CHANGE:</h5>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="mt-0 pb-0">
            <h5 class="subtitle-2 text-right">
              {{ Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(totalChange) }}
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-2">
      <v-card-text>
        <v-btn
          color="success"
          block
          :loading="loading"
          :disabled="totalChange<0 || cart === null"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          color="error"
          block
          class="mt-2"
          :to="{name: 'cashiering'}">
          Back
        </v-btn>
      </v-card-text>
    </v-card>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>

    <v-dialog
      v-model="qtyDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedPayment.toUpperCase() }}
        </v-card-title>

        <v-card-text>
          <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
            <!-- Customer Name -->
            <v-text-field
              v-model="payment"
              dense
              type="number"
              placeholder="0.00"
              @keypress.enter="addPayment"
            ></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="qtyDialog = false"
          >
            Close
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="addPayment"
          >
            Add Payment
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import blob from '@/composables/blob'
import printJS from 'print-js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'

export default {
  props: ['cart','uniqueId', 'transaction'],
  setup(props, { emit, root }) {
    const loading = ref(false)
    // const transaction = ref(null)
    const qtyDialog = ref(false)
    const message = ref('')
    const customer = ref('')
    const customer_type = ref('WALKIN')
    const customer_code = ref(null)
    const customer_name = ref(null)
    const status = ref('')
    const formErrorFields = ref({
      customer_typeErrorMessage: '',
      customer_codeErrorMessage: '',
      customer_nameErrorMessage: '',
      statusErrorMessage: '',
    })
    const payments = ref({
      cash: 0,
      gcash: 0,
      bankTransfer: 0,
    })
    const payment = ref(0)
    const selectedPayment = ref('')
    const totalCartItems = ref(0)
    const totalDue = ref(0)
    const totalPayment = ref(0)
    const totalChange = ref(0)
    
    const error = ref(null)
    const receiptData = ref(null)

    const {
      store,
      update,
      responseData,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      customer_type.value = 'WALKIN'
      customer_code.value = null
      customer_name.value = null
      payments.value.cash = 0
      payments.value.gcash = 0
      payments.value.bankTransfer = 0
      totalCartItems.value = 0
      totalDue.value = 0
      totalPayment.value = 0
      totalChange.value = 0
      emit('clearTable')
    }

    const printReceipt = async (referenceNumber) => {
      await blob(`${process.env.VUE_APP_URI}/api/cart/${referenceNumber}/receipt`, receiptData, loading, error)
      if (error.value === null) {
        console.log(receiptData.value)
        const url = window.URL.createObjectURL(new Blob([receiptData.value], { type: 'application/pdf' }))
        printJS(url)
        // window.open(url)
      }
    }

    const submit = async () => {
      const formData = {
        customerType: customer_type.value,
        customerCode: customer_code.value,
        customerName: customer_name.value,
        payments: payments.value,
        amountDue: totalDue.value,
        cart: props.cart,
      }

      let response = null

      if(root.$route.params.id !== undefined) {
        const uri = `${process.env.VUE_APP_URI}/api/cart/${root.$route.params.id}`
        await update(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/cart`
        await store(uri, loading, formData, formErrorFields)
      }
      
      // console.log(responseData.val)
      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        printReceipt(responseData.value.transactionNumber)
        reset()
      }
    }

    const paymentBreakdownWasClick = val => {
      selectedPayment.value = val
      qtyDialog.value = true

      if (selectedPayment.value === 'cash') {
        payment.value = payments.value.cash
      } else if (selectedPayment.value === 'gcash') {
        payment.value = payments.value.gcash
      } else if (selectedPayment.value === 'bank transfer') {
        payment.value = payments.value.bankTransfer
      }
    }

    const computeTotals = () => {
      totalPayment.value = parseFloat(payments.value.cash) + parseFloat(payments.value.gcash) + parseFloat(payments.value.bankTransfer)
      totalDue.value = 0
      totalCartItems.value = 0
      totalChange.value = 0

      if(props.cart !== null) {
        props.cart.forEach(element => {
          totalDue.value += parseFloat(element.srp) * parseInt(element.quantity)
          totalCartItems.value += parseInt(element.quantity)
        });
      }

      const changeDue = parseFloat(totalPayment.value) - parseFloat(totalDue.value)
      totalChange.value = changeDue
    }

    watch(() => props.uniqueId, val => {
      computeTotals()
    })

    const addPayment = () => {
      if (selectedPayment.value === 'cash') {
        payments.value.cash = payment.value
      } else if (selectedPayment.value === 'gcash') {
        payments.value.gcash = payment.value
      } else if (selectedPayment.value === 'bank transfer') {
        payments.value.bankTransfer = payment.value
      }

      qtyDialog.value = false
      computeTotals()
    }

    const customerTypeWasChange = val => {
      if(customer_type.value === 'WALKIN') {
        emit('customerTypeChange', 'WALKIN')
      } else {
        emit('customerTypeChange', 'RESELLER')
      }

      customer_name.value = null
      customer_code.value = null
    }

    const getCustomer = async () => {
      await get(`${process.env.VUE_APP_URI}/api/cart/get-customer/${customer_type.value}/${customer_code.value}`, customer, null, error)
      if(error.value !== null) {
        formErrorFields.value.customer_codeErrorMessage = error.value.data.errors.customer_code
        error.value = null
      } else {
        error.value = null
        customer_name.value = customer.value.name
        // emit('customerTypeChange', customer.value.type)
      }
    }

    watch(() => props.transaction, val => {
      if (val !== null) {
          customer_code.value = val.customer_code
          customer_type.value = val.buyer_type.toUpperCase()
          customer_name.value = val.recipient.toUpperCase()
          payments.value.cash = val.cash
          payments.value.gcash = val.gcash
          payments.value.bankTransfer = val.bank_transfer
          computeTotals()
      }
    })

    // getDataOnload()

    return {
      customer_type,
      customer_code,
      customer_name,
      customerTypeWasChange,
      status,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      addPayment,
      qtyDialog,

      payments,
      payment,
      paymentBreakdownWasClick,
      selectedPayment,

      totalCartItems,
      totalDue,
      totalPayment,
      totalChange,

      getCustomer,
    }
  },

  components: {
    snackbar,
  },
}
</script>
